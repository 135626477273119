import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import logoImage from './images/logobranco.png';

const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = event => {
            if (!ref.current || ref.current.contains(event.target)) return;
            handler(event);
        };
        document.addEventListener("mousedown", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, [ref, handler]);
};

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [open, setOpen] = useState(false);
    const node = React.useRef();
    const navigate = useNavigate();
    useOnClickOutside(node, () => setOpen(false));

    const handleClick = () => {
        setClick(!click);
        setOpen(!open);
        if (!open) {
            setOpen(true);
        }
    };

    /*const handleLinkClick = (sectionId) => {
        closeMobileMenu();
        navigate("/"); // Navigate to the main page
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                const offset = 70; // Adjust offset for fixed header
                const elementPosition = section.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            }
        }, 100);
    };*/

    const handleLinkClick = (sectionId) => {
        closeMobileMenu();
        navigate("/", { state: { sectionId } }); // Pass the section ID to the main page
    };


    const closeMobileMenu = () => {
        setClick(false);
        setOpen(false);
    };

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
            if (click) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        } else {
            setButton(true);
            setOpen(false);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <div className={click ? 'menu-icon active' : 'menu-icon'} onClick={handleClick}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {open && <div className='overlay' onClick={closeMobileMenu}></div>}
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <a className='nav-links' onClick={() => handleLinkClick('header')} >
                                Home
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-links' onClick={() => handleLinkClick('story-section')}>
                                Artist Catalog
                            </a>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to="/order"
                                className='nav-links'
                                onClick={closeMobileMenu} // Close the mobile menu when the link is clicked
                            >
                                Order Now
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-links' onClick={() => handleLinkClick('cta-section')}>
                                I am an Artist
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-links' onClick={() => handleLinkClick('supporting-content-section')}>
                                Feedback
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-links' onClick={() => handleLinkClick('email-form-section')} >
                                Contact us!
                            </a>
                        </li>
                    </ul>
                    <span className='navbar-logo' onClick={() => handleLinkClick('header')}>
                        <img src={logoImage} alt='Logo' className='navbar-logo-image' />
                    </span>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
