import React, { useState, useEffect, useRef } from "react";
import { MinimalButton, ScrollMode, SpecialZoomLevel, Viewer, ViewMode, Worker } from "@react-pdf-viewer/core";
import { thumbnailPlugin, ThumbnailDirection } from "@react-pdf-viewer/thumbnail";
import { NextIcon, pageNavigationPlugin, PreviousIcon } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

const Magazine = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isMobile = screenWidth <= 768;
    const pdfContainerRef = useRef(null);

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToNextPage, jumpToPreviousPage } = pageNavigationPluginInstance;

    const thumbnailPluginInstance = thumbnailPlugin();
    const { Thumbnails } = thumbnailPluginInstance;

    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        window.scrollTo(0, 0);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const container = pdfContainerRef.current;
        if (!container) return;

        let isScrolling = false;

        const smoothScroll = (event) => {
            if (isScrolling) return;
            isScrolling = true;
            setTimeout(() => {
                container.scrollLeft += event.deltaY * 0.3; // Adjust speed
                isScrolling = false;
            }, 50);
        };

        container.addEventListener("wheel", smoothScroll);
        return () => container.removeEventListener("wheel", smoothScroll);
    }, []);

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    overflow: "hidden",
                }}
            >
                {/* PDF Viewer - Horizontal Scroll */}
                <div
                    ref={pdfContainerRef}
                    style={{
                        flexGrow: 1,
                        overflowX: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        scrollBehavior: "smooth",
                        position: "relative",
                        marginTop: "20px",
                    }}
                >
                    <Viewer
                        key={isMobile ? "mobile" : "desktop"}
                        defaultScale={SpecialZoomLevel.PageFit}
                        scrollMode={ScrollMode.Horizontal} // Horizontal scrolling for all devices
                        viewMode={isMobile ? ViewMode.SinglePage : ViewMode.DualPage}
                        fileUrl="/zine.pdf"
                        plugins={[pageNavigationPluginInstance, thumbnailPluginInstance, zoomPluginInstance]}
                    />
                </div>

                {/* Navigation Controls - Only for Desktop */}
                {!isMobile && (
                    <>
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "1rem",
                                transform: "translateY(-50%)",
                                zIndex: 10,
                            }}
                        >
                            <MinimalButton onClick={jumpToPreviousPage}>
                                <PreviousIcon />
                            </MinimalButton>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                right: "1rem",
                                transform: "translateY(-50%)",
                                zIndex: 10,
                            }}
                        >
                            <MinimalButton onClick={jumpToNextPage}>
                                <NextIcon />
                            </MinimalButton>
                        </div>
                    </>
                )}

                {/* Zoom Controls */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "1rem",
                        backgroundColor: "#f8f8f8",
                        borderTop: "1px solid rgba(0, 0, 0, .3)",
                        zIndex: 10,
                    }}
                >
                    {/* Zoom Controls */}
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                        <ZoomOutButton />
                        <ZoomPopover />
                        <ZoomInButton />
                    </div>
                </div>

                {/* Thumbnails Below */}
                <div
                    style={{
                        height: "12rem",
                        overflowX: "auto",
                        backgroundColor: "#f8f8f8",
                        padding: "0.5rem",
                        overflow: "hidden", // Prevent scrolling
                    }}
                >
                    <Thumbnails thumbnailDirection={ThumbnailDirection.Horizontal} />
                </div>
            </div>
        </Worker>
    );
};

export default Magazine;
